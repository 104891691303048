// import React, { useState } from "react";
// import { Box, Container, Grid, Typography } from "@mui/material";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import FundRaise from "./../../assets/images/donate/fundraise.jpg";
// import Banner from "../../constants/Banner/Banner";

// // Images Import
// import Counselling1 from "./../../assets/images/Education Counselling/Counselling1.jpeg";
// import Counselling2 from "./../../assets/images/Education Counselling/Counselling2.jpeg";
// import Counselling3 from "./../../assets/images/Education Counselling/Counselling3.jpeg";
// import Counselling5 from "./../../assets/images/Education Counselling/Counselling6.jpeg";

// import Motivation3 from "./../../assets/images/MotivationalPrograms/Motivation3.jpeg";
// import Motivation10 from "./../../assets/images/MotivationalPrograms/Motivation10.jpg";
// import Motivation9 from "./../../assets/images/MotivationalPrograms/Motivation9.jpg";
// import Motivation2 from "./../../assets/images/MotivationalPrograms/Motivation2.jpeg";
// import Motivation5 from "./../../assets/images/MotivationalPrograms/Motivatioan5.jpg";
// import Motivation6 from "./../../assets/images/MotivationalPrograms/Motivation6.jpeg";
// import Motivation7 from "./../../assets/images/MotivationalPrograms/Motivatioan7.jpg";
// import Motivation8 from "./../../assets/images/MotivationalPrograms/Motivation8.jpeg";

// import PublicTalk4 from "./../../assets/images/PublicTalks/PublicTalk4.jpeg";
// import PublicTalk5 from "./../../assets/images/PublicTalks/PublicTalk5.jpg";
// import PublicTalk2 from "./../../assets/images/PublicTalks/PublicTalk2.jpg";
// import PublicTalk1 from "./../../assets/images/PublicTalks/PublicTalk1.jpeg";
// import PublicTalk6 from "./../../assets/images/PublicTalks/PublicTalk6.jpg";
// import PublicTalk7 from "./../../assets/images/PublicTalks/PublicTalk7.jpg";
// import PublicTalk8 from "./../../assets/images/PublicTalks/PublicTalk8.jpeg";
// import PublicTalk9 from "./../../assets/images/PublicTalks/PublicTalk9.jpeg";

// import EducationalTour1 from "./../../assets/images/EducationTour/Tour1.jpeg";
// import EducationalTour2 from "./../../assets/images/EducationTour/Tour2.jpeg";
// import EducationalTour3 from "./../../assets/images/EducationTour/Tour3.jpeg";
// import EducationalTour4 from "./../../assets/images/EducationTour/Tour4.jpeg";
// import EducationalTour5 from "./../../assets/images/EducationTour/Tour5.jpeg";
// import EducationalTour6 from "./../../assets/images/EducationTour/Tour6.jpeg";
// import EducationalTour7 from "./../../assets/images/EducationTour/Tour7.jpeg";
// import EducationalTour8 from "./../../assets/images/EducationTour/Tour8.jpeg";

// import LibraryImg1 from "./../../assets/images/ComputerClasswithLibrary/Library2.jpeg";
// import ComputerClassImg1 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass1.jpeg";
// import ComputerClassImg3 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass3.jpeg";
// import ComputerClassImg4 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass4.jpeg";
// import LibraryImg7 from "./../../assets/images/ComputerClasswithLibrary/Library7.jpg";
// import LibraryImg8 from "./../../assets/images/ComputerClasswithLibrary/Library8.jpg";
// import LibraryImg9 from "./../../assets/images/ComputerClasswithLibrary/Library9.jpg";

// import ComputerClass5 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass2.jpeg";
// import LibraryImg3 from "./../../assets/images/ComputerClasswithLibrary/Library6.jpg";

// // Events Images Array
// const eventsImages = [
//   {
//     name: "TIET & AspiraSys Internship Program 2025",
//     images: [
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_1.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_2.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_3.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_4.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_5.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_6.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_7.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_8.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_9.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_10.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_11.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_12.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_13.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_14.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_15.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_16.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_17.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_18.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_19.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_20.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_21.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_22.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_23.jpg",
//     ],
//   },
//   {
//     name: "Felicitation Ceremony 2024",
//     images: [
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guests.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guest1.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation1.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation14.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation2.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation3.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation4.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation5.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation6.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation7.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation8.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation9.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation10.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation11.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation12.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation13.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation15.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation16.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation17.jpg",
//       "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation18.jpg",
//     ],
//   },
//   {
//     name: "Motivational Programs",
//     images: [
//       Motivation3,
//       Motivation10,
//       Motivation9,
//       Motivation2,
//       Motivation5,
//       Motivation6,
//       Motivation7,
//       Motivation8,
//     ],
//   },
//   {
//     name: "Educational Counsellings",
//     images: [Counselling1, Counselling2, Counselling3, Counselling5],
//   },
//   {
//     name: "Public Talks",
//     images: [
//       PublicTalk4,
//       PublicTalk5,
//       PublicTalk2,
//       PublicTalk1,
//       PublicTalk6,
//       PublicTalk7,
//       PublicTalk8,
//       PublicTalk9,
//     ],
//   },
//   {
//     name: "Educational Tours",
//     images: [
//       EducationalTour1,
//       EducationalTour2,
//       EducationalTour3,
//       EducationalTour4,
//       EducationalTour5,
//       EducationalTour6,
//       EducationalTour7,
//       EducationalTour8,
//     ],
//   },
//   {
//     name: "Computer Class",
//     images: [ComputerClassImg4, ComputerClass5, ComputerClassImg1, LibraryImg3],
//   },
//   {
//     name: "TIET Library",
//     images: [LibraryImg1, LibraryImg7, LibraryImg8, LibraryImg9],
//   },
// ];

// // Carousel Component
// const CarouselModal = ({ open, onClose, images, initialIndex }) => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     initialSlide: initialIndex,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     arrows: true,
//   };

//   if (!open) return null;

//   return (
//     <Box
//       position="fixed"
//       top={0}
//       left={0}
//       width="100%"
//       height="100%"
//       bgcolor="rgba(0, 0, 0, 0.8)"
//       display="flex"
//       alignItems="center"
//       justifyContent="center"
//       zIndex={1000}
//       onClick={onClose}
//     >
//       <Box width="80%" height="80%" onClick={(e) => e.stopPropagation()}>
//         <Slider {...settings}>
//           {images.map((image, index) => (
//             <Box
//               key={index}
//               display="flex"
//               alignItems="center"
//               justifyContent="center"
//             >
//               <img
//                 src={image}
//                 alt={`Carousel Image ${index + 1}`}
//                 style={{ width: "100%", height: "100%", objectFit: "contain" }}
//               />
//             </Box>
//           ))}
//         </Slider>
//       </Box>
//     </Box>
//   );
// };

// // Main Component
// const WorkshopAndEvents = () => {
//   const [selectedEvent, setSelectedEvent] = useState(null);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handleImageClick = (event, index) => {
//     setSelectedEvent(event);
//     setCurrentIndex(index);
//   };

//   return (
//     <>
//       <Banner
//         Image={FundRaise}
//         BannerHeading={"Workshop & Events"}
//         IsHomeBanner={false}
//       />
//       <Box>
//         <Container>
//           {eventsImages.map((event, index) => (
//             <Box key={index} marginBottom={4} paddingTop={3}>
//               <Typography
//                 align="center"
//                 variant="h5"
//                 fontWeight={"bold"}
//                 gutterBottom
//               >
//                 {event.name}
//               </Typography>
//               <Grid container spacing={3} justifyContent="center">
//                 {event.images.map((image, imageIndex) => (
//                   <Grid key={imageIndex} item xs={6} sm={3}>
//                     <img
//                       src={image}
//                       alt={`${event.name} ${imageIndex + 1}`}
//                       style={{
//                         width: "100%",
//                         height: "150px",
//                         objectFit: "cover",
//                         cursor: "pointer",
//                       }}
//                       onClick={() => handleImageClick(event, imageIndex)}
//                     />
//                   </Grid>
//                 ))}
//               </Grid>
//             </Box>
//           ))}
//         </Container>
//       </Box>

//       {selectedEvent && (
//         <CarouselModal
//           open={!!selectedEvent}
//           onClose={() => setSelectedEvent(null)}
//           images={selectedEvent.images}
//           initialIndex={currentIndex}
//         />
//       )}
//     </>
//   );
// };

// export default WorkshopAndEvents;

// import React, { useState } from "react";
// import {
//   Box,
//   Container,
//   Grid,
//   Typography,
//   Modal,
//   Backdrop,
// } from "@mui/material";
// import Slider from "react-slick";

// const imageUrls = Array.from(
//   { length: 13 },
//   (_, i) =>
//     `https://tiet-congrats.s3.ap-south-1.amazonaws.com/MPSG6W/${i + 1}.jpeg`
// );

// const SchoolAlbum = () => {
//   const [open, setOpen] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handleOpen = (index) => {
//     setCurrentIndex(index);
//     setOpen(true);
//   };

//   const handleClose = () => setOpen(false);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     initialSlide: currentIndex,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//   };

//   return (
//     <Container>
//       <Typography textAlign="center" variant="h4" fontWeight="bold" mb={5}>
//         Mohammadia Primary School - Workshop (08-Feb-2025)
//       </Typography>

//       <Grid container spacing={2} justifyContent="center">
//         {imageUrls.map((src, index) => (
//           <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
//             <img
//               src={src}
//               alt={`Event ${index + 1}`}
//               onClick={() => handleOpen(index)}
//               style={{
//                 width: "100%",
//                 height: "300px",
//                 objectFit: "cover",
//                 cursor: "pointer",
//                 borderRadius: "10px",
//               }}
//             />
//           </Grid>
//         ))}
//       </Grid>

//       <Modal
//         open={open}
//         onClose={handleClose}
//         BackdropComponent={Backdrop}
//         BackdropProps={{ timeout: 500 }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             height: "100vh",
//             width: "100vw",
//             bgcolor: "rgba(0, 0, 0, 0.9)",
//           }}
//         >
//           <Box sx={{ width: "90vw", height: "90vh" }}>
//             <Slider {...settings} initialSlide={currentIndex}>
//               {imageUrls.map((src, index) => (
//                 <Box
//                   key={index}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "90vh",
//                     width: "90vw",
//                   }}
//                 >
//                   <img
//                     src={src}
//                     alt={`Event ${index + 1}`}
//                     style={{
//                       width: "100%",
//                       height: "100%",
//                       objectFit: "contain",
//                     }}
//                   />
//                 </Box>
//               ))}
//             </Slider>
//           </Box>
//         </Box>
//       </Modal>
//     </Container>
//   );
// };

// export default SchoolAlbum;
// import React, { useState } from "react";
// import {
//   Box,
//   Container,
//   Grid,
//   Typography,
//   Modal,
//   Backdrop,
// } from "@mui/material";
// import Slider from "react-slick";

// const imageUrls = Array.from(
//   { length: 13 },
//   (_, i) =>
//     `https://tiet-congrats.s3.ap-south-1.amazonaws.com/MPSG6W/${i + 1}.jpeg`
// );

// const SchoolAlbum = () => {
//   const [open, setOpen] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const handleOpen = (index) => {
//     setCurrentIndex(index);
//     setOpen(true);
//   };

//   const handleClose = () => setOpen(false);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     initialSlide: currentIndex,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     arrows: true,
//   };

//   return (
//     <Container>
//       <Box
//         sx={{
//           backgroundColor: "#f9f9f9",
//           padding: 3,
//           borderRadius: "10px",
//           marginBottom: 5,
//         }}
//       >
//         <Typography textAlign="center" variant="h4" fontWeight="bold" mb={3}>
//           TIET - Workshop for Kids
//         </Typography>
//         <Typography textAlign="center" variant="h6" mb={3}>
//           Date: 08-Feb-2025
//         </Typography>
//         <Typography textAlign="justify" variant="body1">
//           TIET, in partnership with Mohammadia Primary School, successfully
//           hosted a "Basic Computer Awareness" workshop at the TIET office today.
//           The workshop, designed to introduce Grade 6 students to the
//           fundamentals of computer usage, saw 20 enthusiastic participants.
//           <br />
//           <br />
//           The session began with an introduction to the basics of operating a
//           computer, followed by a dive into simple software tools like Notepad
//           and MS Paint. The aim was to make the session both informative and
//           fun. After the theoretical discussions, students had the opportunity
//           to engage in hands-on activities where they explored the tools and
//           created their own projects.
//           <br />
//           <br />
//           The students were fully engaged throughout the session, with many
//           showing great excitement and interest in the activities. To celebrate
//           their participation and achievements, each student was awarded a
//           certificate of participation.
//           <br />
//           <br />
//           The workshop proved to be a valuable learning experience for everyone
//           involved. The students left feeling more confident in their computer
//           skills and excited to explore new possibilities in the digital world.
//         </Typography>
//       </Box>

//       <Grid container spacing={2} justifyContent="center">
//         {imageUrls.map((src, index) => (
//           <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
//             <img
//               src={src}
//               alt={`Event ${index + 1}`}
//               onClick={() => handleOpen(index)}
//               style={{
//                 width: "100%",
//                 height: "300px",
//                 objectFit: "cover",
//                 cursor: "pointer",
//                 borderRadius: "10px",
//               }}
//             />
//           </Grid>
//         ))}
//       </Grid>

//       <Modal
//         open={open}
//         onClose={handleClose}
//         BackdropComponent={Backdrop}
//         BackdropProps={{ timeout: 500 }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             height: "100vh",
//             width: "100vw",
//             bgcolor: "rgba(0, 0, 0, 0.9)",
//           }}
//         >
//           <Box sx={{ width: "90vw", height: "90vh" }}>
//             <Slider {...settings} initialSlide={currentIndex}>
//               {imageUrls.map((src, index) => (
//                 <Box
//                   key={index}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "90vh",
//                     width: "90vw",
//                   }}
//                 >
//                   <img
//                     src={src}
//                     alt={`Event ${index + 1}`}
//                     style={{
//                       width: "100%",
//                       height: "100%",
//                       objectFit: "contain",
//                     }}
//                   />
//                 </Box>
//               ))}
//             </Slider>
//           </Box>
//         </Box>
//       </Modal>
//     </Container>
//   );
// };

// export default SchoolAlbum;

import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Modal,
  Backdrop,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const imageUrls = [
  ...Array.from(
    { length: 13 },
    (_, i) =>
      `https://tiet-congrats.s3.ap-south-1.amazonaws.com/MPSG6W/${i + 1}.jpeg`
  ),
  ...Array.from(
    { length: 19 },
    (_, i) =>
      `https://tiet-congrats.s3.ap-south-1.amazonaws.com/MPSG6W/cd${i + 1}.jpeg`
  ),
];

const SchoolAlbum = () => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleBack = () => {
    navigate(-1);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#f9f9f9",
          padding: 3,
          borderRadius: "10px",
          marginBottom: 5,
        }}
      >
        <IconButton onClick={handleBack} sx={{ marginBottom: 3 }}>
          <ArrowBack fontSize="large" />
        </IconButton>
        <Typography textAlign="center" variant="h4" fontWeight="bold" mb={3}>
          TIET - Workshop for Kids
        </Typography>
        <Typography textAlign="center" variant="h6" mb={3}>
          Date: 08-Feb-2025
        </Typography>
        <Typography textAlign="justify" variant="body1">
          TIET, in partnership with Mohammadia Primary School, successfully
          hosted a "Basic Computer Awareness" workshop at the TIET office today.
          The workshop, designed to introduce Grade 6 students to the
          fundamentals of computer usage, saw 20 enthusiastic participants.
          <br />
          <br />
          The session began with an introduction to the basics of operating a
          computer, followed by a dive into simple software tools like Notepad
          and MS Paint. The aim was to make the session both informative and
          fun. After the theoretical discussions, students had the opportunity
          to engage in hands-on activities where they explored the tools and
          created their own projects.
          <br />
          <br />
          The students were fully engaged throughout the session, with many
          showing great excitement and interest in the activities. To celebrate
          their participation and achievements, each student was awarded a
          certificate of participation.
          <br />
          <br />
          The workshop proved to be a valuable learning experience for everyone
          involved. The students left feeling more confident in their computer
          skills and excited to explore new possibilities in the digital world.
        </Typography>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        {imageUrls.map((src, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <img
              src={src}
              alt={`Event ${index + 1}`}
              onClick={() => handleOpen(index)}
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            bgcolor: "rgba(0, 0, 0, 0.9)",
          }}
        >
          <Box sx={{ width: "90vw", height: "90vh" }}>
            <Slider {...settings} initialSlide={currentIndex}>
              {imageUrls.map((src, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    width: "90vw",
                  }}
                >
                  <img
                    src={src}
                    alt={`Event ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default SchoolAlbum;
