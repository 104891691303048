import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import CountSetTimeout from "../../constants/Counter/CountMethods";
import ImageCarousel from "../../constants/ImageCarousel/ImageCarousel";
import AutoplayClient from "../../constants/ImageCarousel/AutoplayClient";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import WhatsAppWidgetIcon from "../../assets/json/WhatAppWidget";
import "./Home.scss";
import {
  School as EducationIcon,
  GroupOutlined as WomenEmpowermentIcon,
} from "@mui/icons-material";

// Home Banner
import BannerImg1 from "./../../assets/images/banner1.jpg";
import BannerImg2 from "./../../assets/images/banner_2.jpg";

// Partner Images
import PartnerImg1 from "./../../assets/images/partners/aiwa.jpg";
import PartnerImg2 from "./../../assets/images/partners/amp.jpg";
import PartnerImg3 from "./../../assets/images/partners/ccc.jpg";
import PartnerImg4 from "./../../assets/images/partners/ihssass.jpg";
import PartnerImg5 from "./../../assets/images/partners/marshall.jpg";
import PartnerImg6 from "./../../assets/images/partners/pec.jpeg";
import PartnerImg7 from "./../../assets/images/partners/Partner7.png";

// Award Images
import AwardImg1 from "./../../assets/images/awards/Award_2019.jpg";
import AwardImg2 from "./../../assets/images/awards/award_2021.jpg";
import AwardImg3 from "./../../assets/images/awards/Award_2020.jpeg";
import AwardImg4 from "./../../assets/images/awards/Award_2020_1.jpeg";
import { Link } from "react-router-dom";
import QuotedContent from "../../constants/QuotedContent/QuotedContent";
import AwardsCarousel from "../../constants/ImageCarousel/AwardsCarousel";

const carouselItems = [BannerImg1, BannerImg2];

const partnerCarouselItems = [
  PartnerImg1,
  PartnerImg2,
  PartnerImg3,
  PartnerImg4,
  PartnerImg5,
  PartnerImg6,
  PartnerImg7,
];

const awardCarousel = [AwardImg1, AwardImg3, AwardImg4, AwardImg2];

const Home = () => {
  return (
    <>
      <Box className={"sections homebanner"}>
        <ImageCarousel carouselItems={carouselItems} itemsShow={1} />
        <Box className={"bannerContent"}>
          <Typography
            variant="h2"
            color={"#fff"}
            fontWeight={"bold"}
            gutterBottom
            zIndex={2}
          >
            TOGETHER WE CAN.
          </Typography>
          {/* <Button
            variant="contained"
            startIcon={<VolunteerActivismIcon />}
            size="large"
            component={Link}
            to="https://rzp.io/l/pqlTYtD"
            target="_blank"
          >
            Donate TIET
          </Button> */}
        </Box>
      </Box>
      <Box className={"sections"} p={8} px={{ xs: 4, md: 8 }}>
        <Container>
          <QuotedContent
            content={
              "Revival of a community is a complex process that demands the sacrifice of collective resources, manpower, money, and energy for a common good cause.Given the historical, socio-cultural, and existential need, comprehensive, holistic reforms in all walks of life must be thought of. Reforms in education is one of the major steps that need to be taken by the pernambut community in general across the country but more so in Pernambut.Education alone can liberate the community from various impending shackles. The current overall education system in Pernambut requires holistic revamp.TIET is committed to assist the community through appropriate guidance for attaining quality education, skill development, career building and other societal economic development works through education."
            }
          />
        </Container>
      </Box>

      <Box className={"statisticSections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h4"
            mb={5}
            fontWeight={"bold"}
          >
            Our Imapct
          </Typography>
          <Grid
            container
            flexDirection={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent={"space-around"}
          >
            <Grid item>
              <CountSetTimeout
                number={150}
                incrementBy={10}
                duration={5}
                title={"Events"}
              />
            </Grid>
            <Grid item>
              <CountSetTimeout
                number={12000}
                incrementBy={100}
                duration={5}
                title={"Students"}
              />
            </Grid>
            {/* <Grid item>
              <CountSetTimeout
                number={50}
                incrementBy={5}
                duration={5}
                title={"Empowerments"}
              />
            </Grid> */}
            <Grid item>
              <CountSetTimeout
                number={3}
                incrementBy={1}
                duration={5}
                title={"Cities"}
              />
            </Grid>
            <Grid item>
              <CountSetTimeout
                number={50}
                incrementBy={5}
                duration={5}
                title={"Scholarships"}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <Box className={"sections"} p={8} px={{xs: 0, md: 8}}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h5"
            mb={5}
            fontWeight={"bold"}
          >
            TIET ACHIEVEMENTS - 2021
          </Typography>
          <Grid container spacing={2}>
            {AchievementContent.map((achievements, key) => (
              <Grid item xs={4} key={key}>
                <GridContent
                  GridImage={achievements.Image}
                  Heading={achievements.Title}
                  Paragraph={achievements.Paragraph}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h5"
            mb={5}
            fontWeight={"bold"}
          >
            Our Programmes
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                backgroundColor: "#f5f5f5",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Link to={"/education"} className="ourProgrammes">
                <EducationIcon sx={{ fontSize: 40 }} />
                <Typography
                  variant="h5"
                  fontWeight={"bold"}
                  color="text.secondary"
                  gutterBottom
                >
                  {"EDUCATION"}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  Education, nutrition and holistic development of children
                </Typography> */}
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                backgroundColor: "#f5f5f5",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Link to={"/womenEmpowerment"} className="ourProgrammes">
                <WomenEmpowermentIcon sx={{ fontSize: 40 }} />
                <Typography
                  variant="h5"
                  fontWeight={"bold"}
                  color="text.secondary"
                  gutterBottom
                >
                  {"WOMEN EMPOWERMENT"}
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  Empowering adolescent girls & women through community engagement
                </Typography> */}
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h5"
            mb={5}
            fontWeight={"bold"}
          >
            Our Partners
          </Typography>
          <AutoplayClient carouselItems={partnerCarouselItems} />
        </Container>
      </Box>
      <Box className={"sections awardCarousel"} p={8} px={{ xs: 0, md: 8 }}>
        <Container maxWidth="md">
          <Typography
            textAlign={"center"}
            variant="h5"
            mb={5}
            fontWeight={"bold"}
          >
            Awards and Recognitions
          </Typography>

          <AwardsCarousel carouselItems={awardCarousel} />
        </Container>
      </Box>
      <Box>
        <WhatsAppWidgetIcon />
      </Box>
    </>
  );
};

export default Home;

//HomePage
