// import React from "react";
// import {
//   Grid,
//   Card,
//   CardMedia,
//   CardContent,
//   Typography,
//   Box,
//   Container,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";

// import Banner from "../../constants/Banner/Banner";
// import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

// // Assuming Support2 is the correct image path
// import Support2 from "./../../assets/images/Corporate.png";

// const individualSupport = () => {
//   const navigate = useNavigate();

//   return (
//     <>
//       <Banner
//         Image={Support2}
//         BannerHeading={"Harnessing the Strength of Community"}
//         IsHomeBanner={true}
//       ></Banner>

//       <Box className={"sections"} p={8} px={{ xs: 4, md: 8 }}>
//         <Container>
//           <p className="quotedPara">
//             <FormatQuoteIcon />
//             At TIET, we hold conviction in making a positive difference in our
//             community by empowering our young through education and training. By
//             collaborating with corporations, we hope to bridge the gap between
//             education and employment by offering internship opportunities to our
//             trained students. Through this collaboration, we intend to not only
//             improve our students' abilities and knowledge, but also provide them
//             vital experience working in a corporate office. We firmly think that
//             this relationship will benefit both students and companies.
//           </p>
//         </Container>
//       </Box>
//       <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
//         <Container>
//           <Typography
//             textAlign={"center"}
//             variant="h4"
//             mb={5}
//             fontWeight={"bold"}
//           >
//             Our Partner Schools
//           </Typography>
//           <Grid container spacing={4} justifyContent="center">
//             {/* Mohammadia School Card */}
//             <Grid item xs={12} md={6}>
//               <Card
//                 sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
//                 onClick={() => navigate("/InternshipAlbum")}
//               >
//                 <CardMedia
//                   component="img"
//                   height="300"
//                   image="https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_3.jpg"
//                   alt="Mohammadia School"
//                 />
//                 <CardContent>
//                   <Typography variant="h5" fontWeight="bold" gutterBottom>
//                     Mohammadia Primary School
//                   </Typography>
//                   <Typography variant="body1">
//                     In collaboration with TIET, Mohammadia Primary School hosted
//                     a "Basic Computer Awareness" workshop to introduce students
//                     to the digital world.
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//     </>
//   );
// };

// export default individualSupport;

import React from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Banner from "../../constants/Banner/Banner";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

// Assuming Support2 is the correct image path
import Support2 from "./../../assets/images/Corporate.png";
import aspiraysys from "./../../assets/images/partners/aspirasys.jpg";

const IndividualSupport = () => {
  // ✅ Change function name to uppercase
  const navigate = useNavigate();

  return (
    <>
      <Banner
        Image={Support2}
        BannerHeading={"Harnessing the Strength of Community"}
        IsHomeBanner={true}
      ></Banner>

      <Box className={"sections"} p={8} px={{ xs: 4, md: 8 }}>
        <Container>
          <p className="quotedPara">
            <FormatQuoteIcon />
            At TIET, we hold conviction in making a positive difference in our
            community by empowering our young through education and training. By
            collaborating with corporations, we hope to bridge the gap between
            education and employment by offering internship opportunities to our
            trained students. Through this collaboration, we intend to not only
            improve our students' abilities and knowledge, but also provide them
            vital experience working in a corporate office. We firmly think that
            this relationship will benefit both students and companies.
          </p>
        </Container>
      </Box>
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h4"
            mb={5}
            fontWeight={"bold"}
          >
            Our Corporate Partners
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            {/* Mohammadia School Card */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => navigate("/InternshipAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image="https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_3.jpg"
                  alt="Corporate_Asprysys"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    🌟 Inauguration of Internship Program 🌟
                  </Typography>
                  <Typography variant="body1">
                    We are delighted to announce that TIET (Talent
                    Identification and Empowerment Trust) in Pernambut has
                    partnered with AspiraSys, embarking on an exciting journey
                    of growth and empowerment. Together, we have launched an
                    Internship Program designed to bridge the gap between
                    academia and industry.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default IndividualSupport; // ✅ Export with the updated name
