import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Modal,
  Backdrop,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

import PublicTalk4 from "./../../assets/images/PublicTalks/PublicTalk4.jpeg";
import PublicTalk5 from "./../../assets/images/PublicTalks/PublicTalk5.jpg";
import PublicTalk2 from "./../../assets/images/PublicTalks/PublicTalk2.jpg";
import PublicTalk1 from "./../../assets/images/PublicTalks/PublicTalk1.jpeg";
import PublicTalk6 from "./../../assets/images/PublicTalks/PublicTalk6.jpg";
import PublicTalk7 from "./../../assets/images/PublicTalks/PublicTalk7.jpg";
import PublicTalk8 from "./../../assets/images/PublicTalks/PublicTalk8.jpeg";
import PublicTalk9 from "./../../assets/images/PublicTalks/PublicTalk9.jpeg";

const imageUrls = [
  PublicTalk4,
  PublicTalk5,
  PublicTalk2,
  PublicTalk1,
  PublicTalk6,
  PublicTalk7,
  PublicTalk8,
  PublicTalk9,
];

const PublicTalkAlbum = () => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleBack = () => {
    navigate(-1);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#f9f9f9",
          padding: 3,
          borderRadius: "10px",
          marginBottom: 5,
        }}
      >
        <IconButton onClick={handleBack} sx={{ marginBottom: 3 }}>
          <ArrowBack fontSize="large" />
        </IconButton>
        <Typography textAlign="center" variant="h4" fontWeight="bold" mb={3}>
          Public Talks
        </Typography>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        {imageUrls.map((src, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <img
              src={src}
              alt={`Event ${index + 1}`}
              onClick={() => handleOpen(index)}
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            bgcolor: "rgba(0, 0, 0, 0.9)",
          }}
        >
          <Box sx={{ width: "90vw", height: "90vh" }}>
            <Slider {...settings} initialSlide={currentIndex}>
              {imageUrls.map((src, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    width: "90vw",
                  }}
                >
                  <img
                    src={src}
                    alt={`Event ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default PublicTalkAlbum;
