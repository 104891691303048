// import React from "react";
// import { Box, Container, Grid, Typography, Rating } from "@mui/material";
// import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

// const stories = [
//   {
//     quote:
//       "Before I joined the Communicative English Class (TIET), I didn't know how to speak English. I knew a little bit, but since joining this class, Alhamdulillah, I have gained many benefits. I didn't know the rules of grammar, public speaking, group discussions, etc. Now, I can speak English with full confidence whenever I need to. This class has been very useful for me. THANK YOU SO MUCH!",
//     user: "--HAFSA FARHEEN",
//     rating: 5,
//   },

//   {
//     quote:
//       "The Communicative English class was extremely useful and interesting. I thoroughly enjoyed learning in the class. The tutors were very approachable and knowledgeable. They provided clear and concise explanations, making concepts easy to understand. The class was interactive and engaging, which helped me stay focused. I appreciated the opportunity to practice my communication skills. The tutors offered valuable feedback and suggestions for improvement. Overall, the class was well-structured and effective. I now feel more confident in my ability to communicate effectively.",
//     user: "--NEELUFAR",
//     rating: 5,
//   },
//   {
//     quote:
//       "Dear Mam, As our class ends, we deeply appreciate your dedication, patience, and inspiring teaching. Your guidance has not only improved our English but also boosted our confidence. You created a supportive and engaging learning environment, making every session valuable. Your encouragement has left a lasting impact, and we will carry forward your lessons. Thank you for your hard work and motivation. May Allah bless you with honor, health, and happiness..",
//     user: "--SHAHIDA",
//     rating: 5,
//   },
//   {
//     quote:
//       "Dear Mam, I am deeply grateful for your guidance and support throughout this course. Your belief in me transformed my hesitation into confidence and my silence into fluent speech. Your patience, kindness, and encouragement made learning enjoyable and inspiring. This course was more than just language lessons—it was a journey of self-growth and resilience. A heartfelt thanks to TIET Women's Wings for this wonderful opportunity. May Allah bless you with success and the strength to continue spreading knowledge.",
//     user: "--SADIYA",
//     rating: 5,
//   },
//   {
//     quote:
//       "Dear Ma’am, Thank you for your support and encouragement throughout this course. Your guidance has helped us gain confidence and improve our English skills. The activities and discussions made learning enjoyable and effective. Though the class is ending, we will continue to practice and cherish your lessons. We truly appreciate your hard work and kindness. You have made a lasting impact on our lives.",
//     user: "--MUSBEEN",
//     rating: 5,
//   },
//   {
//     quote:
//       "Tiet communicative is very useful to us.you take very clever decisions. Every Muslim  women are safely teached in our home.I am a home maker .Tiet give a chance to me time .this online class developed my personality. Allah gives to tiet sponsor for all benefits. Thank you for tiet give me opportunity jazakallah khairan kasira",
//     user: "--NAFEESA",
//     rating: 5,
//   },
//   {
//     quote:
//       "The class was engaging and informative. The instructor provided clear explanations, making complex topics easy to understand. The interactive activities helped me practice speaking and listening skills, and the class had a friendly atmosphere that encouraged participation. I feel more confident in my English skills after attending.",
//     user: "--ALINA SUMAN",
//     rating: 5,
//   },
//   {
//     quote:
//       "Masha Allah, our tutor is very kind and an excellent trainer. She taught me in a very polite and patient manner. Thank you, TIET Women's Wing group… Keep it up!",
//     user: "--WAHEEDA",
//     rating: 5,
//   },
//   {
//     quote:
//       "The English class was very useful and interesting and it will be very helpful for me",
//     user: "--AZMATHUNNISSA",
//     rating: 5,
//   },
//   {
//     quote:
//       "I really enjoyed the Spoken English course with our instructor. The way they taught was excellent. Thank you for giving me the opportunity to improve my skills.",
//     user: "--SIDRA",
//     rating: 5,
//   },
//   {
//     quote:
//       "The classes were excellent, and I have improved significantly through the sessions. I would highly recommend this course to anyone interested in enhancing their English skills.",
//     user: "--MARZIYA",
//     rating: 5,
//   },
//   {
//     quote: " I admire and appreciate your exceptional teaching abilities.",
//     user: "--KUBRA",
//     rating: 5,
//   },
//   {
//     quote:
//       "Alhamdulillah, this course has been extremely beneficial to me. It has helped me improve my English skills and gain more confidence in speaking. I am truly grateful for this valuable learning experience.",
//     user: "--HOORIYA",
//     rating: 5,
//   },
//   {
//     quote:
//       "The course was very useful and empowering us with English conversation to a good extent. The tutor gave us excellent coaching. ",
//     user: "--NADIRA",
//     rating: 5,
//   },
//   {
//     quote:
//       "This course is very helpful for me and the teacher was great and made learning fun....I enjoyed the activities that help me practice speaking...Thank you for a wonderful experience...I will recommend the course to my friends...",
//     user: "--ALIYA",
//     rating: 5,
//   },
//   {
//     quote:
//       "She is one of the best teachers I have ever witnessed. Admired with her style of explaining things. Very polite and knowledgeable.",
//     user: "--AMREEN",
//     rating: 5,
//   },
//   {
//     quote:
//       "We had great opportunity for learning with such a good teacher. We have improved our way of speaking English ",
//     user: "--HARMAIN",
//     rating: 5,
//   },
//   {
//     quote:
//       "Excellent - I really liked your class very much and your helpful and very much approachable. ",
//     user: "--SELVI",
//     rating: 5,
//   },
// ];

// const StoriesGrid = () => {
//   return (
//     <Grid container spacing={3}>
//       {stories.map((story, index) => (
//         <Grid item xs={12} md={6} key={index}>
//           <Box
//             border={1}
//             borderColor="primary.main"
//             p={3}
//             display="flex"
//             flexDirection="column"
//             justifyContent="space-between"
//             textAlign="justify"
//             borderRadius={2}
//             boxShadow={3}
//           >
//             <Rating
//               name="rating"
//               value={story.rating}
//               readOnly
//               sx={{ mb: 1 }}
//             />
//             <Typography variant="body1" fontStyle="italic">
//               <FormatQuoteIcon color="primary" fontSize="large" /> {story.quote}{" "}
//               <FormatQuoteIcon color="primary" fontSize="large" />
//             </Typography>
//             <Typography
//               variant="subtitle1"
//               align="right"
//               fontWeight="bold"
//               mt={2}
//             >
//               {story.user}
//             </Typography>
//           </Box>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

// const WomenEmpowerment = () => {
//   return (
//     <>
//       {/* Certificate Distribution Announcement */}
//       <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
//         <Container>
//           <Typography variant="h5" fontWeight="bold" gutterBottom>
//             🎉 Successful Completion of Online Certificate Distribution Program
//             🎉
//           </Typography>
//           <Typography variant="body1">
//             Assalamu Alaikum / Greetings,
//             <br />
//             <br />
//             Alhamdulillah! On <strong>Sunday, 9th March 2025</strong>, we
//             successfully conducted the{" "}
//             <strong>Online Certificate Distribution Program</strong>, led by{" "}
//             <strong>Mrs. Uzefa Rashida</strong>, along with our esteemed online
//             tutor <strong>Mrs. Najeeba Umar</strong>. 💐✨
//             <br />
//             <br />
//             We proudly awarded{" "}
//             <strong>certificates to 25 dedicated learners</strong> who
//             successfully completed the{" "}
//             <strong>Online Spoken English Class</strong>. 🎓👏 Their commitment
//             and perseverance have truly been inspiring!
//             <br />
//             <br />
//             A big congratulations to all the learners! May this milestone open
//             doors to new opportunities and success. 🌟💖
//             <br />
//             <br />
//             A heartfelt thanks to everyone who made this event a success.
//             Jazakallah Khair! 🙌
//             <br />
//             <br />
//             #TIET #CertificateDistribution #SpokenEnglishSuccess
//             #EmpoweringWomen
//           </Typography>
//         </Container>
//       </Box>

//       {/* Stories of Change */}
//       <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
//         <Container>
//           <Typography variant="h5" fontWeight="bold" gutterBottom>
//             Stories of Change
//           </Typography>
//           <StoriesGrid />
//         </Container>
//       </Box>
//     </>
//   );
// };

// export default WomenEmpowerment;

import React from "react";
import { Box, Container, Typography, Rating } from "@mui/material";

const stories = [
  {
    quote:
      "Before I joined the Communicative English Class (TIET), I didn't know how to speak English. I knew a little bit, but since joining this class, Alhamdulillah, I have gained many benefits. I didn't know the rules of grammar, public speaking, group discussions, etc. Now, I can speak English with full confidence whenever I need to. This class has been very useful for me. THANK YOU SO MUCH TIET!",
    user: "--HAFSA FARHEEN",
    rating: 5,
  },
  {
    quote:
      "The Communicative English class was extremely useful and interesting. I thoroughly enjoyed learning in the class. The tutors were very approachable and knowledgeable. They provided clear and concise explanations, making concepts easy to understand. The class was interactive and engaging, which helped me stay focused. I appreciated the opportunity to practice my communication skills. The tutors offered valuable feedback and suggestions for improvement. Overall, the class was well-structured and effective. I now feel more confident in my ability to communicate effectively.",
    user: "--NEELUFAR",
    rating: 5,
  },
  {
    quote:
      "As our class ends, we deeply appreciate your dedication, patience, and inspiring teaching. Your guidance has not only improved our English but also boosted our confidence. You created a supportive and engaging learning environment, making every session valuable. Your encouragement has left a lasting impact, and we will carry forward your lessons. Thank you for your hard work and motivation. May Allah bless you with honor, health, and happiness..",
    user: "--SHAHIDA",
    rating: 5,
  },
  {
    quote:
      "I am deeply grateful for your guidance and support throughout this course. Your belief in me transformed my hesitation into confidence and my silence into fluent speech. Your patience, kindness, and encouragement made learning enjoyable and inspiring. This course was more than just language lessons—it was a journey of self-growth and resilience. A heartfelt thanks to TIET Women's Wings for this wonderful opportunity. May Allah bless you with success and the strength to continue spreading knowledge.",
    user: "--SADIYA",
    rating: 5,
  },
  {
    quote:
      "Thank you for your support and encouragement throughout this course. Your guidance has helped us gain confidence and improve our English skills. The activities and discussions made learning enjoyable and effective. Though the class is ending, we will continue to practice and cherish your lessons. We truly appreciate your hard work and kindness. You have made a lasting impact on our lives.",
    user: "--MUSBEEN",
    rating: 5,
  },
  {
    quote:
      "Tiet communicative is very useful to us. You take very clever decisions. Every Muslim woman is safely taught in our home. I am a homemaker. TIET gave me a chance to take time for myself. This online class developed my personality. Allah gives to TIET sponsor for all benefits. Thank you for TIET giving me this opportunity, Jazakallah Khairan Kasira.",
    user: "--NAFEESA",
    rating: 5,
  },
  {
    quote:
      "The class was engaging and informative. The instructor provided clear explanations, making complex topics easy to understand. The interactive activities helped me practice speaking and listening skills, and the class had a friendly atmosphere that encouraged participation. I feel more confident in my English skills after attending.",
    user: "--ALINA SUMAN",
    rating: 5,
  },
  {
    quote:
      "Masha Allah, our tutor is very kind and an excellent trainer. She taught me in a very polite and patient manner. Thank you, TIET Women's Wing group… Keep it up!",
    user: "--WAHEEDA",
    rating: 5,
  },
  {
    quote:
      "The English class was very useful and interesting and it will be very helpful for me.",
    user: "--AZMATHUNNISSA",
    rating: 5,
  },
  {
    quote:
      "I really enjoyed the Spoken English course with our instructor. The way they taught was excellent. Thank you for giving me the opportunity to improve my skills.",
    user: "--SIDRA",
    rating: 5,
  },
  {
    quote:
      "The classes were excellent, and I have improved significantly through the sessions. I would highly recommend this course to anyone interested in enhancing their English skills.",
    user: "--MARZIYA",
    rating: 5,
  },
  {
    quote: "I admire and appreciate your exceptional teaching abilities.",
    user: "--KUBRA",
    rating: 5,
  },
  {
    quote:
      "Alhamdulillah, this course has been extremely beneficial to me. It has helped me improve my English skills and gain more confidence in speaking. I am truly grateful for this valuable learning experience.",
    user: "--HOORIYA",
    rating: 5,
  },
  {
    quote:
      "The course was very useful and empowered us with English conversation to a great extent. The tutor gave us excellent coaching.",
    user: "--NADIRA",
    rating: 5,
  },
  {
    quote:
      "This course is very helpful for me and the teacher was great and made learning fun. I enjoyed the activities that helped me practice speaking. Thank you for a wonderful experience. I will recommend the course to my friends.",
    user: "--ALIYA",
    rating: 5,
  },
  {
    quote:
      "She is one of the best teachers I have ever witnessed. Admired with her style of explaining things. Very polite and knowledgeable.",
    user: "--AMREEN",
    rating: 5,
  },
  {
    quote:
      "We had a great opportunity for learning with such a good teacher. We have improved our way of speaking English.",
    user: "--HARMAIN",
    rating: 5,
  },
  {
    quote:
      "Excellent - I really liked your class very much, and you were very helpful and approachable.",
    user: "--SELVI",
    rating: 5,
  },
];

const WomenEmpowerment = () => {
  return (
    <>
      {/* Certificate Distribution Announcement */}
      <Box p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            align="center"
          >
            🎉 Successful Completion of Online Certificate Distribution Program
            🎉
          </Typography>
          <Typography variant="body1" align="justify">
            <br />
            On <strong>Sunday, 9th March 2025</strong>, we successfully
            conducted the{" "}
            <strong>Online Certificate Distribution Program</strong> led by{" "}
            <strong>
              Prof. Mrs. M. A. Uzefa Rashida (M.A., B.Ed., SET.), Core Member,
              TIET Women's Wing
            </strong>
            , along with our esteemed online tutors{" "}
            <strong>Mrs. Najeeba Umar (M.A., B.Ed., M.Phil.)</strong> and{" "}
            <strong>
              Mrs. Asifa Abdul Azeez (D.T.Ed., M.A. (Eng.), B.Ed. (Eng.))
            </strong>
            . 💐✨
            <br />
            <br />
            We proudly awarded{" "}
            <strong>certificates to 25 dedicated homemakers</strong> who
            successfully completed the{" "}
            <strong>Online Spoken English Class</strong>. 🎓👏 Their commitment
            to learning and self-improvement is truly inspiring!
            <br />
            <br />
            A big congratulations to all the learners! May this milestone
            empower them with confidence and open new doors of opportunity. 🌟
            <br />
            <br />
            A heartfelt thanks to our tutors and everyone who contributed to
            making this event a success.
            <br />
          </Typography>
        </Container>

        {/* <Container>
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            align="center"
          >
            🎉 Successful Completion of Online Certificate Distribution Program
            🎉
          </Typography>
          <Typography variant="body1" align="justify">
            Assalamu Alaikum / Greetings,
            <br />
            <br />
            Alhamdulillah! On <strong>Sunday, 9th March 2025</strong>, we
            successfully conducted the{" "}
            <strong>Online Certificate Distribution Program</strong>, led by{" "}
            <strong>Mrs. Uzefa Rashida</strong>, along with our esteemed online
            tutor <strong>Mrs. Najeeba Umar</strong>. 💐✨
            <br />
            <br />
            We proudly awarded{" "}
            <strong>certificates to 25 dedicated learners</strong> who
            successfully completed the{" "}
            <strong>Online Spoken English Class</strong>. 🎓👏 Their commitment
            and perseverance have truly been inspiring!
            <br />
            <br />
            A big congratulations to all the learners! May this milestone open
            doors to new opportunities and success. 🌟💖
            <br />
            <br />
            A heartfelt thanks to everyone who made this event a success.
            Jazakallah Khair! 🙌
            <br />
            <br />
            #TIET #CertificateDistribution #SpokenEnglishSuccess
            #EmpoweringWomen
          </Typography>
        </Container> */}
      </Box>

      {/* Stories of Change */}
      <Box p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            variant="h5"
            fontWeight="bold"
            gutterBottom
            align="center"
          >
            Sucess Stories
          </Typography>
          {stories.map((story, index) => (
            <Box
              key={index}
              p={4}
              my={2}
              textAlign="center"
              borderRadius={2}
              sx={{
                backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
              }}
            >
              <Typography variant="body1" fontStyle="italic">
                {story.quote}
              </Typography>
              <Rating
                name="rating"
                value={story.rating}
                readOnly
                sx={{ my: 1 }}
              />
              <Typography variant="subtitle1" fontWeight="bold">
                {story.user}
              </Typography>
            </Box>
          ))}
        </Container>
      </Box>
    </>
  );
};

export default WomenEmpowerment;
