import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Modal,
  Backdrop,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const imageUrls = [
  ...Array.from(
    { length: 23 },
    (_, i) =>
      `https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_${
        i + 1
      }.jpg`
  ),
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_24.jpeg",
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/internship/IMG_25.jpeg",
];

const InternshipAlbum = () => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleBack = () => {
    navigate(-1);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: currentIndex,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#f9f9f9",
          padding: 3,
          borderRadius: "10px",
          marginBottom: 5,
        }}
      >
        <IconButton onClick={handleBack} sx={{ marginBottom: 3 }}>
          <ArrowBack fontSize="large" />
        </IconButton>
        <Typography textAlign="center" variant="h4" fontWeight="bold" mb={3}>
          🌟 Inauguration of Internship Program 🌟
        </Typography>
        <Typography textAlign="justify" variant="body1">
          We are delighted to announce that TIET (Talent Identification and
          Empowerment Trust) in Pernambut has partnered with AspiraSys,
          embarking on an exciting journey of growth and empowerment. Together,
          we have launched an Internship Program designed to bridge the gap
          between academia and industry.
          <br />
          <br />
          The inauguration ceremony took place on January 18, 2025, at 6:30 PM
          at the TIET Office, High Road, Pernambut.
          <br />
          <br />
          Renowned educationist Dr. Mubarak Kapdi and Mr. Mohammed Imran,
          Executive Director of Colan Infotech, graced the occasion as chief
          guests, inspiring the youth with their valuable insights. During the
          event, Mr. Abdul Malick Firdose (Head of TIET Women’s Wing) and Mrs.
          Ayesha Javeed (Member, TIET Women’s Wing) presented tokens of
          appreciation to the chief guests.
          <br />
          <br />
          The highlight of the evening was the distribution of internship offer
          letters and kits to 20 deserving students, generously provided by our
          training partner, AspiraSys.
          <br />
          <br />
          The event witnessed the enthusiastic participation of nearly 100
          attendees, making it a resounding success.
          <br />
          <br />
          This initiative aims to empower students by equipping them with
          practical skills and enhancing their employability, paving the way for
          a brighter future.
        </Typography>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        {imageUrls.map((src, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <img
              src={src}
              alt={`Event ${index + 1}`}
              onClick={() => handleOpen(index)}
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            bgcolor: "rgba(0, 0, 0, 0.9)",
          }}
        >
          <Box sx={{ width: "90vw", height: "90vh" }}>
            <Slider {...settings} initialSlide={currentIndex}>
              {imageUrls.map((src, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    width: "90vw",
                  }}
                >
                  <img
                    src={src}
                    alt={`Event ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default InternshipAlbum;
