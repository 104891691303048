// import React, { useState } from "react";
// import {
//   Box,
//   Container,
//   Grid,
//   Typography,
//   Modal,
//   Backdrop,
//   IconButton,
// } from "@mui/material";
// import { ArrowBack } from "@mui/icons-material";
// import Slider from "react-slick";
// import { useNavigate } from "react-router-dom";

// // Updated image URLs
// const imageUrls = [
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guests.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guest1.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation1.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation14.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation2.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation3.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation4.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation5.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation6.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation7.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation8.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation9.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation10.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation11.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation12.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation13.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation15.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation16.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation17.jpg",
//   "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation18.jpg",
// ];

// const FilicitationCeremonyAlbum = () => {
//   const [open, setOpen] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const navigate = useNavigate();

//   const handleOpen = (index) => {
//     setCurrentIndex(index);
//     setOpen(true);
//   };

//   const handleClose = () => setOpen(false);

//   const handleBack = () => {
//     navigate(-1);
//   };

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     initialSlide: currentIndex,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     arrows: true,
//   };

//   return (
//     <Container>
//       <Box
//         sx={{
//           backgroundColor: "#f9f9f9",
//           padding: 3,
//           borderRadius: "10px",
//           marginBottom: 5,
//         }}
//       >
//         <IconButton onClick={handleBack} sx={{ marginBottom: 3 }}>
//           <ArrowBack fontSize="large" />
//         </IconButton>
//         <Typography textAlign="center" variant="h4" fontWeight="bold" mb={3}>
//           🌟 TIET Filicitation Ceremony 2024 🌟
//         </Typography>
//         <Typography textAlign="justify" variant="body1">
//           We are delighted to announce that TIET (Talent Identification and
//           Empowerment Trust) in Pernambut has partnered with AspiraSys,
//           embarking on an exciting journey of growth and empowerment. Together,
//           we have launched an Internship Program designed to bridge the gap
//           between academia and industry.
//           <br />
//           <br />
//           The inauguration ceremony took place on January 18, 2025, at 6:30 PM
//           at the TIET Office, High Road, Pernambut.
//           <br />
//           <br />
//           Renowned educationist Dr. Mubarak Kapdi and Mr. Mohammed Imran,
//           Executive Director of Colan Infotech, graced the occasion as chief
//           guests, inspiring the youth with their valuable insights. During the
//           event, Mr. Abdul Malick Firdose (Head of TIET Women’s Wing) and Mrs.
//           Ayesha Javeed (Member, TIET Women’s Wing) presented tokens of
//           appreciation to the chief guests.
//           <br />
//           <br />
//           The highlight of the evening was the distribution of internship offer
//           letters and kits to 20 deserving students, generously provided by our
//           training partner, AspiraSys.
//           <br />
//           <br />
//           The event witnessed the enthusiastic participation of nearly 100
//           attendees, making it a resounding success.
//           <br />
//           <br />
//           This initiative aims to empower students by equipping them with
//           practical skills and enhancing their employability, paving the way for
//           a brighter future.
//         </Typography>
//       </Box>

//       <Grid container spacing={2} justifyContent="center">
//         {imageUrls.map((src, index) => (
//           <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
//             <img
//               src={src}
//               alt={`Event ${index + 1}`}
//               onClick={() => handleOpen(index)}
//               style={{
//                 width: "100%",
//                 height: "300px",
//                 objectFit: "cover",
//                 cursor: "pointer",
//                 borderRadius: "10px",
//               }}
//             />
//           </Grid>
//         ))}
//       </Grid>

//       <Modal
//         open={open}
//         onClose={handleClose}
//         BackdropComponent={Backdrop}
//         BackdropProps={{ timeout: 500 }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             height: "100vh",
//             width: "100vw",
//             bgcolor: "rgba(0, 0, 0, 0.9)",
//           }}
//         >
//           <Box sx={{ width: "90vw", height: "90vh" }}>
//             <Slider {...settings} initialSlide={currentIndex}>
//               {imageUrls.map((src, index) => (
//                 <Box
//                   key={index}
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     height: "90vh",
//                     width: "90vw",
//                   }}
//                 >
//                   <img
//                     src={src}
//                     alt={`Event ${index + 1}`}
//                     style={{
//                       width: "100%",
//                       height: "100%",
//                       objectFit: "contain",
//                     }}
//                   />
//                 </Box>
//               ))}
//             </Slider>
//           </Box>
//         </Box>
//       </Modal>
//     </Container>
//   );
// };

// export default FilicitationCeremonyAlbum;

import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Modal,
  Backdrop,
  IconButton,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const imageUrls = [
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guests.jpg",
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guest1.jpg",
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation1.jpg",
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation14.jpg",
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation2.jpg",
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation3.jpg",
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation4.jpg",
  "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation5.jpg",
];

const FilicitationCeremonyAlbum = () => {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleBack = () => {
    navigate(-1);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#f9f9f9",
          padding: 3,
          borderRadius: "10px",
          marginBottom: 5,
        }}
      >
        <IconButton onClick={handleBack} sx={{ marginBottom: 3 }}>
          <ArrowBack fontSize="large" />
        </IconButton>
        <Typography textAlign="center" variant="h4" fontWeight="bold" mb={3}>
          🌟 TIET Felicitation Ceremony 2024 🌟
        </Typography>
        <Typography textAlign="justify" variant="body1">
          On Sunday, 28th July 2024, the Felicitation Ceremony organized by the
          Talent Identification and Empowerment Trust (TIET), Pernambut, was
          successfully conducted. The event was honored by the presence of Mr.
          Aqeel Panaruna, Former Chairman of the Council for Leather Exports
          (CLE) and Former Chairman of the Leather Sector Skill Council (LSSC),
          as the Chief Guest.
          <br />
          <br />
          The ceremony was further graced by distinguished guests, including:
          <ul>
            <li>Mr. Chuna Khaisar Ahmed</li>
            <li>Mr. Merit Zahoor Ahmed</li>
            <li>Mr. Pallumeera Ahmed Basha</li>
            <li>Mr. Kaka Anees Ahmed</li>
            <li>Mr. K. Muqthar Ahmed</li>
            <li>Mr. K. Fairoz Baqth</li>
          </ul>
          Along with esteemed educationists, entrepreneurs, well-wishers, and
          community leaders.
          <br />
          <br />
          The event took place at AS Function Hall, Charminar Street, Pernambut,
          and witnessed the participation of over 400 gentlemen and ladies.
          <br />
          <br />
          As part of the ceremony, the parents of 21 doctors and 2 Chartered
          Accountants (CAs) were honored for their unwavering support and
          dedication. Furthermore, the accomplishments of 21 doctors, 22 BUMS
          doctors, and 18 Ph.D. holders were celebrated, recognizing their
          significant contributions to their respective fields.
          <br />
          <br />
          The event served as an inspiring platform to acknowledge academic
          excellence and professional achievements, fostering a spirit of
          encouragement within the community.
        </Typography>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        {imageUrls.map((src, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <img
              src={src}
              alt={`Event ${index + 1}`}
              onClick={() => handleOpen(index)}
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                cursor: "pointer",
                borderRadius: "10px",
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
            bgcolor: "rgba(0, 0, 0, 0.9)",
          }}
        >
          <Box sx={{ width: "90vw", height: "90vh" }}>
            <Slider {...settings} initialSlide={currentIndex}>
              {imageUrls.map((src, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "90vh",
                    width: "90vw",
                  }}
                >
                  <img
                    src={src}
                    alt={`Event ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default FilicitationCeremonyAlbum;
