import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Banner from "../../constants/Banner/Banner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import partner1 from "../../assets/images/School_Partners/Alfalah_school.png";
import partner2 from "../../assets/images/School_Partners/GreenValley_School.jpg";
import partner3 from "../../assets/images/School_Partners/Colleege.jpg";
import AlfalahImg1 from "../../assets/images/School_Partners/AlFalahSchool/Image1.jpeg";
import SchoolBanner from "../../assets/images/School_Img.jpg";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const SchoolPartnersCarousels = [partner1, partner2, partner3];

const SchoolPartnerShips = () => {
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    className: "center",
    centerMode: true,
    centerPadding: "0",
  };

  return (
    <>
      <Banner
        Image={SchoolBanner}
        BannerHeading={"Building bridges beyond boundaries"}
        IsHomeBanner={false}
        p={8}
      />
      <Box className={"sections"} p={8} px={{ xs: 4, md: 8 }}>
        <Container>
          <p className="quotedPara">
            <FormatQuoteIcon />
            At TIET, we believe that education is a fundamental right for all
            children. We are dedicated to creating a positive difference in our
            society by raising awareness about important topics through our
            school partnerships program. By cooperating with schools, we hope to
            reach out to the next generation and provide them with the
            information and skills they need to build a better future. Through a
            variety of activities, we assist schools in creating awareness
            programs on critical themes such as environmental conservation,
            mental health, gender equality, and others. Our mission is to
            inspire and educate children to become responsible citizens who can
            contribute to the advancement of our society
          </p>
        </Container>
      </Box>

      <Box
        className={"sections"}
        p={8}
        px={{ xs: 0, md: 8 }}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container style={{ maxWidth: "800px" }}>
          <Typography
            textAlign={"center"}
            variant="h5"
            mb={5}
            fontWeight={"bold"}
          >
            Our School Partners
          </Typography>
          <Slider
            {...settings}
            className="partnerSlider"
            style={{ margin: "auto" }}
          >
            {SchoolPartnersCarousels.map((val, i) => (
              <img
                key={i}
                src={val}
                alt={`Partner ${i}`}
                style={{ height: "100px", width: "auto" }}
              />
            ))}
          </Slider>
        </Container>
      </Box>

      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h4"
            mb={5}
            fontWeight={"bold"}
          >
            Our Activities
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => navigate("/SchoolAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image="https://tiet-congrats.s3.ap-south-1.amazonaws.com/MPSG6W/1.jpeg"
                  alt="Mohammadia School"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    TIET Workshop for Kids
                  </Typography>
                  <Typography variant="body1">
                    Basic Computer Awareness - Mohammadia Primary School
                    Students
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* Al-Falah School Card */}
            <Grid item xs={12} md={6}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => navigate("/AlfalahSchoolAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={AlfalahImg1}
                  alt="Al-Falah School"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    TIET Workshop for Kids
                  </Typography>
                  <Typography variant="body1">
                    Basic Computer Awareness for Al Falah Matriculation School
                    Students.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Mohammadia School Card */}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SchoolPartnerShips;
